.team {
  display: flex;
  flex-wrap: wrap;
  gap: 4rem;
  justify-content: center;
  margin-bottom: 5rem;
  margin-top: 5rem;

  &__item {
  	margin-bottom: 3rem;
    max-width: 40%;

  	> img {
      margin-bottom: 1.4rem;
      border-radius: 8px;
      width: 13em;

      @media (min-width: $desktop) {
        width: 15em;
      }
  	}

    &:nth-child(12n+1) {
      > img {
        background-color: $orange-bright;
      }
    }
    &:nth-child(12n+2) {
      > img {
        background-color: $aqua-bright;
      }
    }
    &:nth-child(12n+3) {
      > img {
        background-color: $blue-bright;
      }
    }
    &:nth-child(12n+4) {
      > img {
        background-color: $yellow-bright;
      }
    }
    &:nth-child(12n+5) {
      > img {
        background-color: $purple-bright;
      }
    }
    &:nth-child(12n+6) {
      > img {
        background-color: $green-bright;
      }
    }
    &:nth-child(12n+7) {
      > img {
        background-color: $orange-bright;
      }
    }
    &:nth-child(12n+8) {
      > img {
        background-color: $aqua-bright;
      }
    }
    &:nth-child(12n+9) {
      > img {
        background-color: $blue-bright;
      }
    }
    &:nth-child(12n+10) {
      > img {
        background-color: $yellow-bright;
      }
    }
    &:nth-child(12n+11) {
      > img {
        background-color: $purple-bright;
      }
    }
    &:nth-child(12n+12) {
      > img {
        background-color: $green-bright;
      }
    }
  }

  &__name {
    font-family: $base-font;
    font-weight: 600;
    font-size: 1.5rem;
    margin-bottom: .5rem;

    @media (min-width: $desktop) {
      font-size: 1.7rem;
      margin-bottom: 0;
    }
  }

  &__position {
    font-size: 1.4rem;
    line-height: 1.3;

    @media (min-width: $desktop) {
      line-height: 2.8rem;
    }
  }

  &-friends {
    display: flex;
    flex-wrap: wrap;
    gap: 4rem;
    justify-content: center;
    margin-top: 3rem;
    width: 100%;


    &__friend {
      margin-bottom: 0;
      width: 40%;

      > p {
        font-size: 2rem;
        font-weight: 600;
        line-height: 3rem;
        margin-bottom: 2px;
      }

      > span {
        font-size: 1.6rem;
        line-height: 2.4rem;
      }

      @media (min-width: $desktop) {
        width: 240px;
      }
    }

    @media (min-width: $desktop) {
      width: 800px;
    }
  }
}
