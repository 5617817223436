.top-nav {
  --logos-gap: 15px;

  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: auto;
  max-width: $page-width;

  &__list {
    align-items: center;
    display: none;
    gap: 10px;
    margin-bottom: 0;

    &-item {
      align-items: center;
      display: flex;
      height: 44px;
      position: relative;
    }
  }

  &__link {
    color: $grey-4;
    display: inline-block;
    font-family: $base-font;
    font-size: 1.6rem;
    font-weight: 100;
    padding: 0 2rem;
    
    &:hover {
      text-decoration: underline;
    }

    &--current {
      font-weight: 600;
    }
  }

  &__logos {
    align-items: center;
    display: flex;
    background: linear-gradient(#000, #000) no-repeat 175px/1px 100%;
    width: 380px;

    > span {
      display: block;
      margin-left: 40px;
      color: #85837F;
    }
  }

  &__logo {
    display: block;
    height: 2em;
    margin: 0;
    padding: .1rem;
    width: 157px;
  }

  @import "top-nav/menu";
}

.top-nav__list {
  @media (min-width: $desktop) {
    display: flex;
    min-width: 550px;
  }
}


