.field {
  @include antialiasing;
  background-color: $grey-0;
  border: 0;
  font-size: 1.6rem;
  padding: 1rem;

  &__search {
    width: 100%;
  }
}
