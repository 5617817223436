.u-font-base {
  font-size: 1.4rem;
}

.u-font-bold {
  font-family: $base-font;
  font-weight: 600;
}

.u-text-align-center {
  text-align: center;
}

.u-lint {
  border: 1px solid red;
}

.u-hidden {
  display: none;
}

.u-push-bottom-and-half {
  margin-bottom: calc(#{$base-unit} + #{$base-unit} / 2);
}

.u-push-bottom {
  margin-bottom: $base-unit;
}

.u-push-top {
  margin-top: $base-unit;
}

.u-push-bottom-triple {
  margin-bottom: calc(#{$base-unit} * 1.5);

  @media (min-width: $desktop) {
    margin-bottom: calc(#{$base-unit} * 3);
  }
}

.u-push-bottom-double {
  margin-bottom: calc(#{$base-unit} * 2);
}

.u-push-top-double {
  margin-top: calc(#{$base-unit} * 2);
}

.u-push-quarter-left {
  margin-left: calc(#{$base-unit} / 4);
}

.u-push-left-quadruple {
  margin-left: calc(#{$base-unit} * 4);
}

.u-push-quarter-bottom {
  margin-bottom: calc(#{$base-unit} / 4);
}

.something-undefined {
  border-radius: 8px;
  border: 2px dotted $grey-2;
  margin-bottom: 1em;
  padding: 1em 1em 0;
}

.u-inline-block {
  display: inline-block;
}

.u-relative {
  position: relative;
}

.u-flush-none {
  padding: 0;
}

.u-push-bottom-none {
  margin-bottom: 0;
}

.u-inline-block {
  display: inline-block;
}

.u-fullwidth {
  width: 100%;
}

.u-hidden {
  display: none;
}

.u-bg-white {
  background-color: $white;
}

.u-ov-hidden {
  overflow: hidden;
}

.u-image-max--12 {
  max-width: 12em;
}

.u-push-left-half {
  margin-left: 10px;
}

.u-emph {
  color: #FCB65D !important;
  font-family: $base-font;
}

.u-super-quote {
  border-left: 2px solid $grey-2;
  margin: 2rem auto 4rem;
  padding: 1rem 2rem 1rem;

  > q {
    font-family: $base-font;
    font-size: 1.6rem;
    font-style: normal;

    @media (min-width: $desktop) {
      font-size: 2rem;
    }
  }

  .author {
    display: block;
    margin-top: 1rem;
  }

  @media (min-width: $desktop) {
    width: 700px;
  }
}

.u-text-intro {
  font-size: 1.6rem;
  line-height: 2.2rem;

  @media (min-width: $desktop) {
    font-size: 2rem;
    line-height: 3rem;
  }
}

.u-heading-link {
  @extend .u-font-bold;
  color: $bebanjo;
  display: inline-block;
  margin-bottom: 1.5rem;
  text-decoration: none !important;

  @media (min-width: $desktop) {
    margin-bottom: 2.5rem;
  }
}

.u-aqua-bright {
  color: $aqua-bright !important;
}

.u-green-bright {
  color: $green-bright !important;
}

.u-blue-bright {
  color: $blue-bright !important;
}

.u-red-bright {
  color: $red-bright !important;
}

.u-orange-bright {
  color: $orange-bright !important;
}

.u-purple-bright {
  color: $purple-bright !important;
}
