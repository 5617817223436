.multiformat-snippet {
  position: relative;

  > div {
    display: none;
  }

  [data-mime-type-selector] {
    appearance: none;
    background: #F2EFE9 url('media/format-selector-indicator.svg') 80% center  no-repeat;
    border: 0;
    border-radius: 3px;
    padding: 4px 25px 4px 10px;
    position: absolute;
    right: 20px;
    text-align: center;
    text-transform: uppercase;
    top: 20px;
  }
}

body[data-mime-type-view="xml"] {

  .language-sh:first-of-type,
  .language-xml {
    display: block !important;
  }
}

body[data-mime-type-view="json"] {

  .language-sh:last-of-type,
  .language-javascript {
    display: block !important;
  }
}
