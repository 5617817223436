.l-company {
  .top-nav__link--company {
    font-weight: 600;
  }

  .details {
    @media (min-width: $desktop) {
      padding-left: 3em;
    }
  }
}

.l-beneficts,
.l-team-beneficts,
.l-catalog-features,
.l-technology {
  @media (min-width: $desktop) {
    margin: auto;
    max-width: 1096px;
  }
}

.l-team-beneficts  {

  .details {

    .details__title {
      @media (min-width: $desktop) {
        pointer-events: none;

        &:after {
          background-image: none;
        }
      }
    }

    > p {
      @media (min-width: $desktop) {
        display: block;
        margin-top: 2rem;
      }
    }

    &--expanded {
      @media (min-width: $desktop) {
        background-color: $grey-1;
      }
    }
  }
}

.l-tab-content-features {

  @media (min-width: $desktop) {
    max-width: $page-width-block;
  }
}

.l-technology {

  .details {
    padding-top: .5rem;
  }

  .details__title {
    @media (min-width: $desktop) {

      &:after {
        background-image: none !important;
      }
    }
  }

  .well--fullwidth {
    @media (min-width: $desktop) {
      margin-left: 3rem;
      width: 600px;
    }
  }
}

.l-technology,
.l-details-features,
.l-catalog-features,
.l-sequence-features,
.l-catchup-features,
.l-rights-features,
.l-publisher-features,
.l-schedule-features {
  max-width: $page-width-block;

  @media (min-width: $desktop) {
    display: flex;
  }

  .details__content {
    @media (min-width: $desktop) {
      margin-top: 0 !important;
      max-width: 800px;
    }
  }

  .details__title {

  	@media (min-width: $desktop) {
  	  display: none
  	}
  }

  .details--expanded {
    @media (min-width: $desktop) {
      background-color: transparent !important;
      padding-top: 0;
    }
  }
}

.l-catalog-features,
.l-sequence-features,
.l-catchup-features,
.l-rights-features,
.l-publisher-features,
.l-schedule-features {

  .details {

    @media (min-width: $desktop) {
      padding-top: 0;
    }
  }

  .details__content {
    @media (min-width: $desktop) {
      margin-left: 5rem;
    }
  }
}

.l-carousel-why-movida {
  .flickity-slider {
    padding: 1rem 0;
  }

  @media (min-width: $desktop) {
    max-width: calc(#{$page-width-block} - 5rem);

    .carousel__slide {
      margin-right: 1rem;
      min-height: 400px;
      padding: 3em 1.5em;
    }
  }
}

.l-help-center-item,
.l-developer  {

  &.grid__item--small-1-2 {

    @media (min-width: $desktop) {
      grid-template-columns: 180px 1fr;
    }
  }
}

.l-help-center-search {
  max-width: 400px;

  &.grid__item--small-1-2 {

    @media (min-width: $desktop) {
      grid-template-columns: 90% 1fr;
    }
  }
}

.l-help-center-detail,
.l-developer-search-results,
.l-developer-detail  {
  @media (min-width: $desktop) {
    padding-left: 10rem !important;

    .heading-link,
    .heading-title {
      margin-left: -10rem;
    }
  }
}

.l-linear-comparative {

  &.bullet-list {
    @media (max-width: $desktop) {
      height: calc(max(30vh, 20vw));
    }
  }
}

.l-about-us {
  display: flex;

  .aside-menu-desktop {
    height: 200px;
  }
}

