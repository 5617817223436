.footer {
  --mdx-logo-width: 224px;
  --logos-gap: 15px;

  padding: 2rem;

  &__parent {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  &__link {
    color: $white;
    display: inline-block;
    font-size: 1.6rem;
    height: 3.2rem;
    line-height: 2.4rem;
    margin-bottom: .6rem;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }

    @media (min-width: $desktop) {
      margin-bottom: 1.2rem;
    }
  }

  &__copy {
    color: $white;
    text-align: center;
    margin-bottom: 0;

    > a {
      color: $white
    }
  }

  &__legal {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    gap: 20px;

    @media (min-width: $desktop) {
      flex-flow: row;
    }
  }

  &__logos {
    align-items: center;
    gap: var(--logos-gap);
    display: flex;

    > span, a {
      display: inline-block;
    }
  }

  &__logo--mdx {
    background: url('/media/mediagenix-logo.svg') no-repeat center left;
    width: var(--mdx-logo-width);
    height: 38px;
  }
}
