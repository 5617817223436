@font-face {
  font-family: 'Poppins';
  src: url('/fonts/Poppins-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Poppins SemiBold';
  src: url('/fonts/Poppins-SemiBold.woff') format('woff');
}

html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

body {
  @include antialiasing;
  -webkit-tap-highlight-color: transparent;
  align-items: center;
  display: flex;
  flex-flow: column;
  font-family: $base-font;
  font-size: 1.6rem;
  overflow-x: hidden;

  &[data-mobile-visible="true"] {
    overflow-y: hidden;

    @media (min-width: $desktop) {
      overflow-y: auto;
    }
  }
}

header {
  min-height: 60px;
  padding: 1.9rem 1.6rem 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;

  &:before {
    background-color: $white;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    opacity: $background-opacity;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  .banner {
    background-color: #DDFEBC;
    margin-left:  -20px;
    width: calc(100% + 40px);
    padding-left: 20px;
    margin-top: 20px;
    border-radius: 0 0 8px 8px;

    > p { 
      margin-bottom: 0;
      padding: 6px;
      margin: auto;
      max-width: 1460px;
      padding-left: 0;
    }
  }
}

a[name] {
  padding-top: 6rem;
  border: 1px solid $grey-4;
}

main,
footer {
  display: block;
  width: 100%;
}

p,
ul,
ol {
  letter-spacing: -.02em;
  line-height: 1.5;
  margin: 0 0 1em 0;
  padding: 0;
}

img {
  height: auto;
  max-width: 100%;
}

footer {
  background-color: $grey-4;
  width: 100%;
}

a {
  color: $bebanjo;
  text-decoration: none;
}

input {
  font-family: $base-font;
  font-size: 1.6rem;

  &::placeholder {
    color: $grey-3;
  }
}

code {
  font-family: $code-font;
  font-size: 1.4rem;
  white-space: pre;
}

pre {
  overflow-x: auto;
}

video {
  border-radius: 3px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, .1);
}

i {
  font-style: italic;
}


