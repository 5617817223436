$categories: (
	'schedule', 'catalog', 'linear', 'rights', 'catchup', 'publisher', 'reporter', 'sequence',
	'our-mission', 'our-strategic', 'our-story', 'our-values', 'our-team',
	'enterprise', 'high-performance', 'environments', 'full-redundacy', 'disaster-recovery',
	'infrastructure-as-code', 'modern', 'security', 'monitoring', 'service-levels');

.aside-menu-desktop {
  display: none;
  flex-shrink: 0;
  height: auto;
  margin-bottom: 100px;
  position: sticky;
  top: 100px;
  width: 240px;

  a {
    color: $grey-4;
    display: inline-block;
    font-family: $base-font;
    font-weight: 600;
    font-size: 2rem;
    line-height: 3rem;
    margin-bottom: 2rem;

    &.aside-menu-desktop__item--active {
      color: $grey-4;
    }
  }

  @media (min-width: $desktop) {
    display: block;

    ~ .details--collapsed {
      display: none;
    }
  }
}

@each $name in $categories {
  .aside-menu-desktop[data-current="#{$name}"] {
    ~ [data-#{$name}] {

      @media (min-width: $desktop) {
        display: block;

        [data-content="#{$name}"] {
          display: block;
        }
      }
    }
  }
}

body[data-current="who-we-are"] {

  .aside-menu__who-we-are {
    color: #fff !important;
  }
}

body[data-current="our-offices"] {

  .aside-menu__our-offices {
    color: #fff !important;
  }
}

body[data-current="our-mission"] {

  .aside-menu__our-mission {
    color: #fff !important;
  }
}

body[data-current="our-values"] {

  .aside-menu__our-values {
    color: #fff !important;
  }
}

body[data-current="our-story"] {

  .aside-menu__our-story {
    color: #fff !important;
  }
}

body[data-current="our-strategic"] {

  .aside-menu__our-strategic {
    color: #fff !important;
  }
}

