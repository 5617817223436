.well {
  background-color: $grey-0;
  color: $grey-4;
  padding: 1.8em 1.5em;

  &__link {
    display: inline-block;
    font-size: 1.4rem;
    font-weight: $base-font;
    margin-bottom: 2em;
    font-weight: 600;

    @media (min-width: $desktop) {
      font-size: 1.6rem;
    }
  }

  &__title {
    font-size: 2.2rem;
    line-height: 2.6rem;
    margin-bottom: 1em;
  }

  &__subtitle {
    font-size: 1.7rem;
    line-height: 2.3rem;
    margin-bottom: 1em;
  }

  &-box {
    background-color: #fff;
    border: 1px solid rgba(214, 212, 206, 1);
    border-radius: 8px;
    margin: 2rem auto 3rem;
    padding: 1.5em;
    width: 100%;

    > p {
      font-family: $base-font;

      &:last-of-type {
        margin-bottom: 0
      }

      > a {
        color: $bebanjo;
        text-decoration: underline;
      }
    }

    @media (min-width: $desktop) {
      padding: 4rem 3rem;
      width: 700px;
    }
  }

  > p {
    font-size: 1.4rem;
    line-height: 3.2rem;

    @media (min-width: $desktop) {
      font-size: 2.2rem;
      line-height: 3.2rem;
    }

    ~ .well__subtitle {
      margin-top: 3em;
    }

    q {
      font-size: 1.7rem;
      font-style: italic;
      font-weight: $base-font;
      line-height: 2.3rem;

      @media (min-width: $desktop) {
        font-size: 2.4rem;
        line-height: 3.2rem;
      }
    }
  }

  &--rounded {
    border-radius: 8px;
  }

  &--with-arrow {
    padding: 1em 2em;
    position: relative;

    > h2 {
      margin-bottom: 0;
    }

    &:after {
      @include bg-svg-image('icon-arrow-left', 26px, 18px);
      content: '';
      display: inline-block;
      position: absolute;
      right: 1.5rem;
      top: calc(50% - 7px);
      transition: right .25s;
    }

    &:hover {
      background-color: $orange-bright;

      &:after {
        right: 1rem;
      }
    }
  }

  &--fullwidth {
    margin-left: -4rem;
    padding: 4rem;
    width: calc(100% + 8rem);
  }

  &--dark {
    * {
      color: $grey-4 !important;
    }

    &:hover {
      background-color: #B6DE73;
    }
  }
}
