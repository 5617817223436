
h1,
h2,
h3,
h4,
h5,
.heading {
  line-height: 1.2;
  margin: 0 0 .25em 0;
}

h1,
.heading--xx-large {
  font-family: $base-font;
  font-weight: 600;
  font-size: 3.6rem;
  font-weight: 600;
  line-height: 4.4rem;

  @media (min-width: $desktop) {
    font-size: 5.2rem;
    line-height: 6.2rem;
  }
}

h2,
.heading--x-large {
  font-family: $base-font;
  font-weight: 600;
  font-size: 3.2rem;
  font-weight: 600;
  line-height: 4rem;

  @media (min-width: $desktop) {
    font-size: 4rem;
    line-height: 4.8rem;
  }
}

h3,
.heading--large {
  font-family: $base-font;
  font-weight: 600;
  font-size: 2.4rem;
  font-weight: 600;
  line-height: 3rem;

  @media (min-width: $desktop) {
    font-size: 3.2rem;
    line-height: 4rem;
  }
}

h4,
.heading--medium {
  font-family: $base-font;
  font-weight: 600;
  font-size: 2rem;
  font-weight: 400;
  line-height: 3rem;
}

h5,
.heading--small {
  font-family: $base-font;
  font-weight: 600;
  font-size: 1.4rem;
  font-weight: 400;
}
