.t-dark {
  background-color: $grey-4;

  .mobile-nav {

    &:before {
      background-color: $grey-4;
    }

    &__link {
      color: $white !important;

      &:hover {
        color: $white;
      }

      &:after {
        background-image: url('/media/icon-chevron-white--down.svg') !important;
      }
    }

    &__button-open {
      @include bg-svg-image('icon-menu-mobile-open--dark', 24px, 24px);
    }

    &__button-close {
      @include bg-svg-image('icon-menu-mobile-close--dark', 24px, 24px);
    }
  }

  header {

    &:before {
      background-color: $grey-4;
    }

    a {
      color: $white;
    }
  }

  .wrapper {

  	&:not(.wrapper--footer, .wrapper--about-us, .wrapper--careers, .wrapper--careers-subscribe, .wrapper--glossary, .wrapper--team) {
      background-color: $grey-0;
    }

    &--about-us,
    &--careers,
    &--careers-subscribe,
    &--glossary,
    &--team {

      * {
        color: $white;
      }
    }
  }

  .aside-menu-desktop {

    a {
      color: $grey-3 !important;
    }

    .aside-menu-desktop__item--active {
      color: $white !important;
    }
  }

  footer {
    border-top: 1px solid #e5e5e5;
  }

  .sup-nav {
    background-color: $grey-4;
    border-bottom: 1px solid $white;
  }

  .top-nav__link {

    &:after {
      @include bg-svg-image('icon-chevron-white--down', 12px, 12px);
    }
  }

  .top-nav__menu {
    background-color: $grey-4;
    border-color: $grey-3;

    h1,
    a {
      color: white;
    }
  }

  .top-nav__menu-col:first-of-type{
    border-color: $grey-3;
  }

  .mobile-nav__sublist h1 {
    color: white
  }
}
