.media {
  margin-top: .5rem;

  &__icon {
    margin-bottom: 2.2rem;
    width: 5em;

    &--min {
      width: 2.2em;
    }
  }

  &__title {
    font-family: $base-font;
    font-weight: 600;
    margin-bottom: 1em;
  }

  &__text {
    font-size: 1.4rem;
    line-height: 2rem;
    margin-bottom: 0;

    @media (min-width: $desktop) {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }

  &__link {
    display: inline-block;
    font-size: 1.4rem;
    margin-top: 4.5rem;

    &--no-gap {
      margin-top: .5rem;
    }

    @media (min-width: $desktop) {
      font-size: 1.6rem;
    }
  }

  &--centered {
    align-items: center;
    display: flex;
    flex-flow: column;
  }
}
