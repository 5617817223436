&-hero {
  display: flex;
  margin-left: 0;
  margin-top: -5rem;
  padding: 4rem 14rem;
  width: calc(100% + 28rem);

  &__text {
    flex-basis: 100%;

    * {
      color: #fff !important;
      text-decoration: none !important;
    }

    > p {
      @extend .heading--medium;
    }

    @media (min-width: $desktop) {
      flex-basis: 50%;
      margin-top: 4rem;
      min-width: 420px;
    }
  }

  &--nent {
    background: $aqua-dark url('/media/nent-hero.svg') no-repeat right;

    @media (min-width: $desktop) {
      height: 424px;
    }
  }

  &--channel-5 {
    background: $purple-dark url('/media/channel-5-hero.svg') no-repeat right;

    @media (min-width: $desktop) {
      height: 424px;
    }
  }

  &--itv {
    background: $blue-dark url('/media/itv-hero.svg') no-repeat right;

    @media (min-width: $desktop) {
      height: 424px;
    }
  }

  &--panel {
    background-color: $grey-1;
    display: flex;
    flex-flow: column;
    gap: 2rem;
    justify-content: space-between;
    margin-bottom: 2rem;
    margin-top: 0 !important;
    padding: 4rem 14rem;
    text-align: center;

    > div {
      margin-bottom: 1rem;

      @media (min-width: $desktop) {
        margin-bottom: 0;
      }
    }

    > div > small {
      font-size: 1.4rem;
    }

    > div > p {
      font-family: $base-font;
      font-weight: 600;
      margin: 0;
    }

    @media (min-width: $desktop) {
      flex-flow: row;
      margin-bottom: 4.5rem;
    }
  }

  @media (min-width: $desktop) {
    margin-top: -9rem;
  }
}
