&--post {
  margin: auto;
  max-width: 998px;

  ul {
    @extend .bullet-list;
    margin-bottom: 2.5rem;
    margin-left: 1rem;
    margin-top: 2rem;

    li {
      margin-bottom: 1.5rem;
    }
  }

  h2, h3, h4, h5, h6 {
    margin-bottom: 1.5rem;
    margin-top: 2.5rem;
  }

  h1 {
    font-family: $base-font;
    font-weight: 600;
    font-size: 2.8rem;
    line-height: 3.5rem;
  }

  h2 {
    font-family: $base-font;
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 3rem;
  }

  h3 {
    font-family: $base-font;
    font-weight: 600;
    font-size: 2rem;
    line-height: 3rem;
  }

  h4,
  h5 {
    font-family: $base-font;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.3rem;
  }

  p > a {
    color: $bebanjo !important;
  }
}
