.release {

  &__year {
    display: inline-block;
    font-size: 1.6rem;
    margin-bottom: 2rem;
  }

  &__title {
    font-size: 1.7rem;
    line-height: 2.3rem;
    margin-bottom: 1rem;
  }

  &__publish {
    font-size: 1.4rem;
  }

  &__row {
    margin-bottom: 2rem;

    &:first-of-type {
      @media (min-width: $desktop) {
        flex-basis: 400px;
      }
    }
  }

  @media (min-width: $desktop) {
    display: flex;
    gap: 3rem;
  }
}
