.link {

  &--bold {
    font-weight: 600;
  }

  &--underline {
    text-decoration: underline;
  }
}
