&-body {

  picture {
    margin-bottom: 3rem;
    margin-top: 3rem;
  }

  &__intro {
    margin-bottom: 5rem;
  }

  p + h3 {
    margin-top: 3rem;
  }
}

