.button {
  border-radius: 30px;
  border: 0;
  color: $white;
  display: inline-block;
  font-family: $base-font;
  font-weight: 600;
  font-size: 1.7rem;
  line-height: 1.4;
  padding: 0.4771em 1.14em;
  text-align: center;
  text-decoration: none !important;
  width: max-content;

  &--bebanjo {
    background-color: $bebanjo;

    &:hover {
      background-color: $bebanjo-bright;
    }
  }

  &--aqua-dark {
    background-color: $aqua-dark;

    &:hover {
      background-color: lighten($aqua-dark, 5%);
    }
  }

  &--green-dark {
    background-color: $green-dark;

    &:hover {
      background-color: lighten($green-dark, 5%);
    }
  }

  &--grey-0 {
    background-color: $grey-0;
    color: $grey-4;

    &:hover {
      background-color: lighten($grey-0, 5%);
    }
  }

  &--grey-1 {
    background-color: $grey-1;
    color: $grey-4;

    &:hover {
      background-color: lighten($grey-1, 5%);
    }
  }

  &--red-dark {
    background-color: $red-dark;

    &:hover {
      background-color: lighten($red-dark, 5%);
    }
  }

  &--purple-dark {
    background-color: $purple-dark;

    &:hover {
      background-color: lighten($purple-dark, 5%);
    }
  }

  &--yellow-dark {
    background-color: $yellow-dark;

    &:hover {
      background-color: lighten($yellow-dark, 5%);
    }
  }

  &--orange-dark {
    background-color: $orange-dark;

    &:hover {
      background-color: lighten($orange-dark, 5%);
    }
  }

  &--blue-dark {
    background-color: $blue-dark;

    &:hover {
      background-color: lighten($blue-dark, 5%);
    }
  }
}

.button-group {

  .button {
    border-radius: 8px;
    width: 100%;
  }
}
