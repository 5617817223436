// job posting
// customer
.content-secondary {

  section > h1:first-of-type {
    @extend .heading--x-large;
  }

  section > a:first-of-type {
    @extend .u-font-bold;
    display: inline-block;
    margin-bottom: 1.5rem;

    @media (min-width: $desktop) {
      margin-bottom: 2.5rem;
    }
  }

  section > h2:first-of-type {
    @extend .heading--medium;
    color: $grey-3;
    font-family: $base-font;
    font-weight: 100;
    margin-bottom: 3rem;
    margin-top: -1rem;
  }

  h1 {
    @extend .heading--large;
  }

  h2 {
    font-family: $base-font;
    font-weight: 600;
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 3rem;

    @media (min-width: $desktop) {
      font-size: 2.8rem;
      line-height: 3rem;
    }
  }

  h3 {
    font-family: $base-font;
    font-weight: 600;
    font-size: 2.4rem;
    font-weight: 600;
    line-height: 3rem;
  }

  h4,
  h5 {
    @extend .heading--medium;
  }
}
