.sup-nav {
  background-color: $grey-0;
  margin: -2rem -2rem 2rem;
  padding: 1rem 3rem;

  &__items {
    display: flex;
    justify-content: space-between;
    margin: auto;
    max-width: $page-width;
  }

  &__item {
    align-items: center;
    display: flex;
    font-family: $base-font;
    font-weight: 600;
    font-size: 1.4rem;
    margin: auto;
  }

  p {
    margin-bottom: 0;
  }

  ~ .mobile-nav {
    top: 37px;
  }

  ~ .mobile-nav__button {
    top: 5.6rem;
  }
}
