.details {
  border-radius: 8px;
  margin-bottom: 3rem;
  padding: 1.5em 1em;

  @media (min-width: $desktop) {
    padding: 1.5em 1em;
  }

  &__title {
    cursor: pointer;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    padding-right: 3rem;
    position: relative;

    &:after {
      content: '';
      display: inline-block;
      position: absolute;
      right: 0;
      top: calc(50% - calc(var(--icon-height) / 2));
    }
  }

  > p,
  .details__content {
    display: none;
    font-size: 1.4rem;
    line-height: 2rem;

    @media (min-width: $desktop) {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }

  &__icon {
    width: 1em;
    height: 1em;
    margin: 0 .8em 0 0;
  }

  &--collapsed {
    background-color: $grey-1;

  	.details__title {
  	  --icon-height: 24px;

  	  &:after {
  	    @include bg-svg-image('icon-details--collapsed', 24px, var(--icon-height));
  	  }
  	}
  }

  &--expanded {
    background-color: $orange-bright;

  	.details__title {
  	  --icon-height: 4px;

      &:after {
        @include bg-svg-image('icon-details--expanded', 24px, var(--icon-height));
      }
    }

    > p,
    .details__content {
     display: block;

      &:first-of-type {
      	margin-top: 2rem;
      }
    }
  }

  &--classic {
    background-color: transparent;
    margin-bottom: 0;

    .details__title {
      color: $grey-4;
      font-size: 2.2rem;

      &:after {
        opacity: .4;
      }

      &:hover {
        color: $grey-4;

        &:after {
          opacity: 1;
        }
      }
    }

    &.details--expanded {
      .details__title {
        color: $grey-4;

        &:after {
          opacity: 1;
        }
      }
    }
  }

  &--dark {
    background-color: $grey-1;
    margin-bottom: 0;

    .details__title, p {
      color: $grey-4 !important;
    }
  }

  @media (min-width: $desktop) {
    padding: 1.5em 2em;
  }
}
