&__menu {
  background-color: $white;
  border-radius: 8px;
  border: 1px solid $grey-1;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, .2);
  display: none;
  left: -8px;
  padding: 2.8rem;
  position: absolute;
  top: 4.5rem;

  &-content {
  	min-width: 180px;
  }

  &-col {
  	flex-basis: auto;
  	padding-left: 2.8rem;

  	> h1 {
      font-size: 1.6rem;
      line-height: 2.4rem;
  	}

    &:first-of-type {
      border-right: 1px solid $grey-1;
      flex-basis: 320px;
      padding-left: 0;
    }

    &-modules {
      display: flex;
      flex-wrap: wrap;
      height: 150px;
      margin-top: -4px;

      .top-nav__menu-link {
        flex-basis: 50%;
      }
    }
  }

  &-link {
    color: $grey-5;
    display: block;
    display: flex;
    align-items: center;
    margin-bottom: 2rem;

    &:last-child {
      margin-bottom: 0;
    }

    &:hover {
      text-decoration: underline;
    }
  }

  &-icon {

    &:before {
      content: '';
      display: inline-block;
    }

    &--schedule {

      &:before {
        @include bg-svg-image('icon-schedule', 30px, 24px);
        background-size: 24px;
      }
    }

    &--linear {

      &:before {
        @include bg-svg-image('icon-linear', 30px, 24px);
        background-size: 24px;
      }
    }

    &--catalog {

      &:before {
        @include bg-svg-image('icon-catalog', 30px, 24px);
        background-size: 24px;
      }
    }


    &--catch-up {

      &:before {
        @include bg-svg-image('icon-catch-up', 30px, 24px);
        background-size: 24px;
      }
    }

    &--reporter {

      &:before {
        @include bg-svg-image('icon-reporter', 30px, 24px);
        background-size: 24px;
      }
    }

    &--publisher {

      &:before {
        @include bg-svg-image('icon-publisher', 30px, 24px);
        background-size: 24px;
      }
    }

    &--rights {

      &:before {
        @include bg-svg-image('icon-rights', 30px, 24px);
        background-size: 24px;
      }
    }

    &--sequence {

      &:before {
        @include bg-svg-image('icon-sequence', 30px, 24px);
        background-size: 24px;
      }
    }
  }

  &--product {
    height: 282px;
    width: 447px;

    .top-nav__menu-link {
      margin-top: 2.5rem;
      margin-bottom: 0;
    }
  }

  &--visible {
    display: flex;
  }
}
