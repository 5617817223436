.stories {
  display: flex;
  flex-flow: column;
  margin-bottom: 3rem;

  @media (min-width: $desktop) {
    flex-flow: row;
  }
}

.story {
  color: $grey-4;
  display: flex;
  flex-basis: 100%;
  flex-flow: column;
  margin-bottom: 3rem;
  padding: 1.8em 1.5em;

  &__logo {
    display: flex;
    justify-content: center;
    margin-bottom: 2rem;
  }

  &__title {
    font-size: 2.2rem;
    line-height: 2.6rem;
    margin-bottom: 1em;
  }

  &__text {
    display: flex;
    flex-flow: column;
    max-width: 350px;
  }

  &__link {
    display: inline-block;
    font-weight: $base-font;
    font-weight: 600;
    margin-bottom: 2em;
  }

  @media (min-width: $desktop) {
    flex-basis: 33%;
  }

  @import "story/story.hero";
  @import "story/story.body";
}

