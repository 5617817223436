.a-screen-reader-text {
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  overflow: hidden;
  position: absolute !important;
  width: 1px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(24, 1fr);
  margin: 0;
  max-width: 100%;

  &__item {
    grid-column: 3 / -3;
    gap: 1.5rem;
    width: 100%;

    @media (min-width: $desktop) {
      gap: 6rem;
    }

    &--x-large {
      grid-template-columns: repeat(4, 1fr);
    }

    &--large {
      grid-template-columns: repeat(3, 1fr);
    }

    &--large-3-6 {
      grid-template-columns: repeat(3, 1fr);

      @media (min-width: $desktop) {
        grid-template-columns: repeat(6, 1fr);
      }
    }

    &--medium {
      grid-template-columns: repeat(2, 1fr);

      &:not(.grid__item--medium-frozen) {
        @media (min-width: $desktop) {
          grid-template-columns: repeat(3, 1fr);
        }
      }
    }

    &--small {
      grid-template-columns: repeat(1, 1fr);
    }

    &--small-1-2 {
      @media (min-width: $desktop) {
        grid-template-columns: repeat(2, 1fr);
      }
    }

    &--without-gap {
      gap: 0;
    }

    > img {
      margin: auto;
      width: 100%;
    }
  }
}

.flex {
  display: flex;

  &--column {
    flex-flow: column;
  }
}

.row {

  &-centered {
    align-items: center;
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
