.career {

  &__heading {
    margin-bottom: 0;
  }

  &__subheading {
    color: $grey-3;
    font-family: $base-font !important;
    margin-bottom: 3rem;
  }

  ul {
    @extend .bullet-list;
  }

  h1 {
    @extend .heading--large;
  }

  h2 {
    font-family: $base-font;
    font-weight: 600;
    font-size: 2.8rem;
    line-height: 3rem;
  }

  h3 {
    font-family: $base-font;
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 3rem;
  }

  h4,
  h5 {
    font-family: $base-font;
    font-weight: 600;
    font-size: 2rem;
    line-height: 3rem;
  }
}
