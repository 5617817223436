// Use: @include bg-svg-image('icon-flag-usa', 28px, 28px);
@mixin bg-svg-image($image, $width, $height, $background-size: false) {
  background-image: url('/media/#{$image}.svg');
  background-repeat: no-repeat;
  height: $height;
  width: $width;
  @if $background-size == true {
    background-size: $width $height;
  }
}

@mixin antialiasing {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
