@mixin underline($position: 'after') {
    &:first-of-type {

      &:#{$position} {
        width: calc(100% + 2rem);
      }
    }

    &:#{$position} {
      content: '';
      display: inline-block;
      width: 100%;
      border-top: 1px solid $grey-4;
    }
}

.bullet-list {
  --offset-left: 1rem;

  li {
    margin-left: var(--offset-left);
    margin-bottom: .5rem;
    position: relative;

    &:before {
      color: $grey-4;
      content: '•';
      margin-left: calc(var(--offset-left) * -1);
      margin-right: calc(var(--offset-left) / 2);
      position: absolute;
    }
  }
}

.description-list {
  display: flex;
  flex-wrap: wrap;
  padding: 2em 1em;

  dt,
  dd {
    flex-basis: 50%;

    &:not(:last-of-type) {
      margin-bottom: 2em;
    }
  }

  dd {
    font-size: 1.4rem;
    line-height: 2rem;

    @media (min-width: $desktop) {
      font-size: 1.6rem;
      line-height: 2.4rem;
    }
  }

  dt {
    font-family: $base-font;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2rem;

    @media (min-width: $desktop) {
      font-size: 2rem;
      line-height: 2.4rem;
    }
  }

  &--2-3 {

    dt {
      flex-basis: 34%;
    }

    dd {
      flex-basis: 65%;
    }
  }

  &--rows {
    display: block;
    padding: 0;

    dt {
      margin-bottom: 1em;
    }

    @media (min-width: $desktop) {
      display: flex;
    }
  }

  &--min {

    dt {
      font-family: $base-font;
      font-weight: 600;
      font-size: 1.4rem;
      font-weight: 600;
      line-height: 1.8rem;

      @media (min-width: $desktop) {
        font-size: 1.6rem;
        line-height: 2rem;
      }
    }
  }

  @media (min-width: $desktop) {
    padding: 2em 3em;
  }
}

.parent-list {

  &__heading {
    font-size: 1.7rem;
    line-height: 2.3rem;
    margin-bottom: 1em;
    overflow: hidden;

    @include underline;
  }

  &__subheading {
    font-size: 1.6rem;
    margin-bottom: .25em;
    line-height: 3rem;

    @include underline;
  }

  &__footer {

    &-top,
    &-bottom {

      > p {
        margin: 1rem 0 0;
      }
    }

    &-top {
      @include underline($position: 'before');
      @include underline($position: 'after');
    }

    &-bottom {
      > p {
        margin-top: 2rem;
      }
    }
  }

  &--min-width {
    @media (min-width: $desktop) {
      min-width: 500px;
    }
  }
}

.numeric-list {
  counter-reset: list-number;

  > li {
    counter-increment: list-number;
    position: relative;

    &:before {
      content: counter(list-number) '.';
      position: absolute;
      left: -20px;
      top: 0;
    }
  }

  &--big {

    > li {
      margin-bottom: 3rem;

      &:before {
        font-size: 3rem;
        left: -50px;
        line-height: 2rem;
      }
    }
  }
}

.inline-list {
  display: flex;
  gap: .9rem;
}
