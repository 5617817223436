.mobile-nav {
  --header-height: 80px;
  background: #fff;
  bottom: 0;
  display: block;
  height: calc(100vh - var(--header-height));
  left: 0;
  margin: 0;
  position: fixed;
  right: 0;
  top: -8px;
  transform: translate(-100%, var(--header-height));
  z-index: 100;

  &__button {
    cursor: pointer;
    margin: 0;
    padding: 0;
    position: fixed;
    right: 2rem;
    top: 1.8rem;
    z-index: 1000;
    text-indent: -99999px;

    &-open {
      @include bg-svg-image('icon-menu-mobile-open', 24px, 24px);
      display: block;
    }

    &-close {
      @include bg-svg-image('icon-menu-mobile-close', 24px, 24px);
      display: none;
    }

    > span {
      font-size: 1.4rem;
    }
  }

  &__checkbox,
  &__submenu-checkbox {
    display: none;
  }

  span.mobile-nav__link {
    color: $grey-4;
    cursor: pointer;
    display: inline-block;
    font-family: $base-font;
    font-weight: 600;
    font-size: 2rem;
    line-height: 3rem;
    margin: 0 1.5rem;
    padding: 1rem;
    width: calc(100% - 3rem);

    &:hover {
      color: $grey-4;
    }

    &:after {
      @include bg-svg-image('icon-chevron--down', 12px, 12px);
      background-position: center;
      content: '';
      display: inline-block;
      margin-left: 1.3rem;
    }
  }

  a.mobile-nav__link {
    color: $grey-4;
    display: inline-block;
    font-size: 1.6rem;
    line-height: 2.4rem;
    margin: 0 1.5rem;
    padding: 1rem;
    width: calc(100% - 3rem);
  }

  &__list {
    max-height: 600px;
    overflow: scroll;
  }

  &__list-item {

  	> span {
      display: inline-block;
      margin: 0 1.5rem;
      padding: 1rem;
      width: calc(100% - 3rem);
    }
  }

  &__sublist {
    display: none;
    margin: 0 2rem;

    h1 {
      font-size: 1.6rem;
      font-family: $base-font;
      font-weight: 600;
      line-height: 2.4rem;
      margin-left: 2.3rem;
      margin-top: 1rem;
    }

    &-modules {
      display: flex;
      flex-wrap: wrap;
      margin-top: 1rem;

      > li {
        flex-basis: 50%;

        .mobile-nav__icon {
          display: flex;
          align-items: center;

          &:before {
            content: '';
            display: inline-block;
          }

          &--schedule {

            &:before {
              @include bg-svg-image('icon-schedule', 30px, 24px);
              background-size: 24px;
            }
          }

          &--linear {

            &:before {
              @include bg-svg-image('icon-linear', 30px, 24px);
              background-size: 24px;
            }
          }

          &--catalog {

            &:before {
              @include bg-svg-image('icon-catalog', 30px, 24px);
              background-size: 24px;
            }
          }


          &--catch-up {

            &:before {
              @include bg-svg-image('icon-catch-up', 30px, 24px);
              background-size: 24px;
            }
          }

          &--reporter {

            &:before {
              @include bg-svg-image('icon-reporter', 30px, 24px);
              background-size: 24px;
            }
          }

          &--publisher {

            &:before {
              @include bg-svg-image('icon-publisher', 30px, 24px);
              background-size: 24px;
            }
          }

          &--rights {

            &:before {
              @include bg-svg-image('icon-rights', 30px, 24px);
              background-size: 24px;
            }
          }

          &--sequence {

            &:before {
              @include bg-svg-image('icon-sequence', 30px, 24px);
              background-size: 24px;
            }
          }
        }
      }
    }

    &--separator {
      border-top: 1px solid $grey-3;
      margin-top: 1rem;
      padding-top: 2rem;
    }
  }

  &:before {
    background-color: $white;
    content: '';
    display: block;
    left: 0;
    opacity: $background-opacity;
    height: 100vh;
    position: absolute;
    top: -7px;
    width: 100%;
    z-index: -1;
  }
}

.mobile-nav__submenu-checkbox:checked {
  ~ .mobile-nav__sublist {
    display: block;
  }

  ~ label .mobile-nav__link {
    color: $grey-4;

    &:after {
      transform: rotate(180deg);
    }
  }
}

.mobile-nav__checkbox:checked {

  ~ .mobile-nav {
    transform: translate(0, 80px);
  }

  ~ .mobile-nav__button {

  	.mobile-nav__button-open {
      display: none;
    }

    .mobile-nav__button-close {
      display: block;
    }
  }
}

.mobile-nav,
.mobile-nav__button {
  @media (min-width: $desktop) {
    display: none;
  }
}



