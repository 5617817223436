[class*="language-"] {
  padding-bottom: 1rem !important;
  padding-top: 1rem !important;
}

.language-plaintext {
  background-color: #f0f0f0;
  padding: 0 .5rem !important;
}

.highlighter-rouge + p {
  margin: 2rem 0;
}

.highlight pre, pre {
  background-color: #f9f7f4 !important;
}

.highlight pre {
  border: 0 !important;
  border-radius: 0 !important;
  padding: 1rem 2rem !important;

  @media (min-width: $desktop) {
    padding: 3.5rem 3rem !important;
  }
}
