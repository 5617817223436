$base-unit: 2rem;

$page-width: 1440px;
$page-width-block: 1218px;

$background-opacity: 1;

$base-font: "Poppins", sans-serif;
$base-semibold-font: $base-font;
$code-font: Consolas, "Andale Mono WT", "Andale Mono", "Lucida Console", "Lucida Sans Typewriter", "DejaVu Sans Mono", "Bitstream Vera Sans Mono", "Liberation Mono", "Nimbus Mono L", Monaco, "Courier New", Courier, monospace;

$desktop: 57.5em;
