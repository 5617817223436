// UI
$ui-colors: (
  'white': #fff,
  'black': #000,
  'grey-0': #f9f7f4,
  'grey-1': #F2EFE9,
  'grey-2': #D6D4CE,
  'grey-3': #757575,
  'grey-4': #181818,
  'grey-5': #181818,
  'blue-dark': #00469B,
  'blue-bright': #779FFF,
  'aqua-dark': #008784,
  'aqua': #49B7B4,
  'aqua-bright': #74D6D3,
  'green-dark': #5C8618,
  'green': #8CB649,
  'green-bright': #B6DE73,
  'yellow-dark': #DB9533,
  'yellow': #FCB65D,
  'yellow-bright': #F7E189,
  'orange-dark': #C6560F,
  'orange': #FF8540,
  'orange-bright': #FFB66E,
  'red-dark': #BF2610,
  'red': #DE5538,
  'red-bright': #FF8664,
  'purple-dark': #5A50C6,
  'purple': #8E7DFA,
  'purple-bright': #C2ACFF,
  'bebanjo': #216CDF,
  'bebanjo-bright': #4E93FC
);

$white: map-get($ui-colors, "white");
$black: map-get($ui-colors, "grey-4");
$grey-0: map-get($ui-colors, "grey-0");
$grey-1: map-get($ui-colors, "grey-1");
$grey-2: map-get($ui-colors, "grey-2");
$grey-3: map-get($ui-colors, "grey-3");
$grey-4: map-get($ui-colors, "grey-4");
$grey-5: map-get($ui-colors, "grey-5");
$blue-dark: map-get($ui-colors, "blue-dark");
$blue-bright: map-get($ui-colors, "blue-bright");
$aqua-dark: map-get($ui-colors, "aqua-dark");
$aqua: map-get($ui-colors, "aqua");
$aqua-bright: map-get($ui-colors, "aqua-bright");
$green-dark: map-get($ui-colors, "green-dark");
$green: map-get($ui-colors, "green");
$green-bright: map-get($ui-colors, "green-bright");
$yellow-dark: map-get($ui-colors, "yellow-dark");
$yellow: map-get($ui-colors, "yellow");
$yellow-bright: map-get($ui-colors, "yellow-bright");
$orange-dark: map-get($ui-colors, "orange-dark");
$orange: map-get($ui-colors, "orange");
$orange-bright: map-get($ui-colors, "orange-bright");
$red-dark: map-get($ui-colors, "red-dark");
$red: map-get($ui-colors, "red");
$red-bright: map-get($ui-colors, "red-bright");
$purple-dark: map-get($ui-colors, "purple-dark");
$purple: map-get($ui-colors, "purple");
$purple-bright: map-get($ui-colors, "purple-bright");
$bebanjo: map-get($ui-colors, "bebanjo");
$bebanjo-bright: map-get($ui-colors, "bebanjo-bright");
