.carousel {
  padding: 2rem 0;
  overflow-x: auto;
  width: 100%;

  &__slide {
    background-color: $grey-0;
    border-radius: 20px;
    margin-bottom: 1rem;
    min-height: 340px;
    opacity: .8;
    padding: 2em 3em;
    position: relative;
    transition: all .2s ease-out;
    width: 280px;

    &-title {
      font-size: 2.8rem;
      line-height: 2.6rem;
      margin-bottom: 1em;
    }

    &-pag {
      bottom: 2rem;
      position: absolute;
    }

    > p {
      font-size: 1.6rem;
    }

    &.is-selected {
      background-color: $yellow-bright;
      margin-top: -1rem;
      opacity: 1;
    }

    &:nth-child(1) {
      &.is-selected {
        background-color: $blue-bright;
      }
    }

    &:nth-child(2) {
      &.is-selected {
        background-color: $aqua-bright;
      }
    }

    &:nth-child(3) {
      &.is-selected {
        background-color: $green-bright;
      }
    }

    &:nth-child(4) {
      &.is-selected {
        background-color: $orange-bright;
      }
    }

    &:nth-child(5) {
      &.is-selected {
        background-color: $red-bright;
      }
    }

    &:nth-child(6) {
      &.is-selected {
        background-color: $purple-bright;
      }
    }

    &:nth-child(7) {
      &.is-selected {
        background-color: $yellow-bright;
      }
    }

    @media (min-width: $desktop) {
      width: 380px;
    }
  }
}
