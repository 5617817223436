.about-us__main {
  margin-top: -2rem;
}

.about-us__section {
  margin-bottom: 6rem;

  .details {
    margin-bottom: 1.2rem;

  	@media (min-width: $desktop) {
      margin-bottom: 2.4rem;
    }
  }
}

.about-us__gallery {
  display: flex;
  flex-wrap: wrap;
  gap: 1.2rem;

  > picture {

    &:first-of-type,
    &:last-of-type {
      flex-basis: calc(61.25% - .6rem);
    }

    &:nth-child(2),
    &:nth-child(3) {
      flex-basis: calc(36.875% - .6rem);
    }
  }

  &--2 {
    margin-bottom: 2rem;

    > picture {

      > img {
        width: 100%;
      }

      &:first-of-type {
        flex-basis: 100%;

        @media (min-width: $desktop) {
          flex-basis: calc(50.8% - .6rem) !important;
        }
      }

      &:last-of-type {
        flex-basis: 100%;

        @media (min-width: $desktop) {
      	  flex-basis: calc(47.625% - .6rem) !important
        }
      }
    }
  }
}

.about-us__pills {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;

  &-pill {
    background-color: $grey-1;
    border-radius: 8px;
    padding: 1rem 2rem 0;
    width: 100%;

    * {
      color: $grey-4 !important;
    }

    @media (min-width: $desktop) {
      width: calc(33% - 1rem);
    }
  }
}

.about-us__list {
  display: flex;
  flex-wrap: wrap;
  margin-top: 3rem;
  width: 100%;

  &-col {
    margin-bottom: 2rem;

    @media (min-width: $desktop) {
      display: flex;
      width: 100%;

      > h3 {
        width: 240px;
      }

      > p {
        width: 500px;
      }
    }
  }
}
