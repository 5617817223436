.search-loading {
  position: relative;
  min-height: 300px;

  .heading-link,
  .heading-title {
    display: none;
  }

  &:after {
    color: $grey-3;
    content: 'Searching…';
    font-family: $base-font;
    font-weight: 600;
    font-size: 3.6rem;
    position: absolute;
    text-align: center;
    top: 3rem;
    width: 100%;
  }

  #search-results * { display: none }
}
