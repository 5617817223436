@mixin hero-image($module, $rotation) {

  &:before {
    @include bg-svg-image('icon-#{$module}', calc(16em + 10vw), calc(16em + 10vw), $background-size: true);
    transform: rotate($rotation);

    @media (min-width: $desktop) {
      @include bg-svg-image('icon-#{$module}', calc(24em + 10vw), calc(24em + 10vw), $background-size: true)
    }
  }
}

.wrapper-parent {
  margin: 0;

  @media (min-width: $desktop) {
    margin: 1rem;
  }
}


.wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  position: relative;

  &__inner {
    max-width: $page-width;
    width: 100%;

    @media (max-width: $desktop) {
      height: auto !important
    }
  }

  &__col {
    width: 100%;
  }

  &--secondary {
    background-color: $grey-0;
    border-radius: 8px;
    margin: 8.5rem 1rem 3rem;
    max-width: 1096px;
    overflow: hidden;
    padding: 4rem 2.5rem;

    @media (min-width: $desktop) {
      margin: 8.5rem auto 10rem auto;
      padding: 5rem 14rem 2.5rem;
    }

    &-nosubnav {
      margin-top: 12.5rem;

      @media (min-width: $desktop) {
        margin-top: 17.6rem;
      }
    }

    a:not(.button) {
      color: $grey-4;
      text-decoration: underline;
    }
  }

  &--team {
    margin: 5rem auto 3rem;
    max-width: calc(1080px + 4rem);
    padding: 2rem;

    @media (min-width: $desktop) {
      margin-top: 15rem;
    }
  }

  &--team-join {
    background: url('/media/team-join-bg-mobile.png') no-repeat center;
    background-size: 75%;
    padding: 8rem 1rem 10rem;
    position: relative;
    text-align: center;
    width: 100%;

    @media (min-width: $desktop) {
      background: url('/media/team-join-bg-desktop.png') no-repeat center;
      background-size: 1848px;
      padding: 12rem 0 16rem;
    }
  }

  &--intro {
    max-width: $page-width;
    padding: 12em 1.5em 1.5em;

    &:before {
      content: '';
      position: absolute;
      z-index: -1;
    }

    &-releases {
      padding-top: 10.6em;

      @media (min-width: $desktop) {
        padding-top: 12.6em;
      }
    }
  }

  &--subintro,
  &--row {
    max-width: 843px;
    padding: 1.8em 1.5em;

    &-landing {

      @media (min-width: $desktop) {
        max-width: 800px;
      }
    }
  }

  &--features-subintro {
    align-items: start;
    max-width: $page-width-block;
    padding: 1.8em 1.5em;

    > p {
      @media (min-width: $desktop) {
        max-width: 845px;
      }
    }
  }

  &--features-overview {
    max-width: calc(#{$page-width-block} - 10rem);
    padding: 1.8em 1.5em;
  }

  &--start {
    align-items: start;
  }

  &--home {
    max-width: 800px;
    overflow: visible;

    &:before {
      @include bg-svg-image('icon-schedule', clamp(20em, calc(22em + 8vw), 38em), calc(28em + 10vw), $background-size: true);
      left: calc(-30% - 10vw);
      top: calc(7rem + 10vh);
      transform: rotate(-30deg);
    }
  }

  &--why-movida,
  &--features,
  &--integrations {
    margin-bottom: 4rem;
    max-width: $page-width-block;
    padding-top: 150px;

    &:before {
      @include bg-svg-image('icon-movida', clamp(16em, calc(16em + 8vw), 38em), calc(16em + 10vw), $background-size: true);
      right: -3rem;
      top: calc(1rem - 4vh);
      transform: rotate(-30deg);
    }

    @media (min-width: $desktop) {
      padding-left: 30rem;
      padding-right: 12rem;

      &:before {
        @include bg-svg-image('icon-movida', clamp(22em, calc(22em + 8vw), 38em), calc(28em + 10vw), $background-size: true);
        left: -10%;
        right: auto;
        transform: rotate(10deg);
      }
    }
  }

  &--bg-catalog {
    max-width: $page-width-block;
    padding-bottom: 4.5em;
  }

  &--bg-image {
    max-width: $page-width-block;
    padding-bottom: 2.5em;
  }

  &--catalog-features,
  &--schedule-features,
  &--linear-features,
  &--linear-comparative {
    @media (min-width: $desktop) {
      align-items: start;
      max-width: $page-width - 100px;
    }
  }

  &--catalog-features {
    padding-top: 2.8em;
  }

  &--linear-comparative {
    background-color: $grey-0;

    .heading {
      margin-left: -2rem;
    }
  }

  &--clients,
  &--clients-integrations {
    background-color: $grey-0;
    margin-bottom: 2em;
    max-width: $page-width-block;
    padding-bottom: 3em;
    padding-top: 3.5em;
  }

  &--clients-integrations {
    background-color: $white;
    max-width: 100%;
    padding-bottom: 0;
    padding-top: 1.5em;
  }

  &--releases {
    background-color: $grey-0;
    padding: 3em;

    &:nth-of-type(odd) {
      background-color: $white;
    }
  }

  &--beneficts {
    max-width: 1196px;
    padding-bottom: 3.5em;
    padding-top: .5em;

    @media (min-width: $desktop) {
      padding-top: 3.5em;
    }
  }

  &--team-beneficts {
    max-width: 1196px;
    padding-bottom: 5.5em;
  }

  &--slides {
    background-color: $yellow-bright;
    padding-bottom: 3.5em;
    padding-top: 3.5em;
  }

  &--why-movida {
    align-items: start;
  }

  &--footer {
    max-width: 1196px;
    padding-bottom: 3.5em;
    padding-top: 4em;

    .wrapper__inner {
      max-width: 920px;
    }
  }

  &--contact-block,
  &--apply,
  &--integrations-block,
  &--landing-block {
    background-color: $grey-1;
    padding: 2.5em;
    text-align: center;

    .wrapper__inner {
      max-width: 700px;
    }

    @media (min-width: $desktop) {
      padding: 6.5em;
    }
  }

  &--landing-block {
    width: 100%;
  }

  &--customers {
    max-width: 1145px;
  }

  &--customer-story {
    margin-top: 7rem;
    padding-bottom: 5rem;
    //border: 1rem solid white;

    @media (min-width: $desktop) {
      margin-top: 8.5rem;
    }
  }

  // legacy
  &--customer {
    padding-bottom: 0;
    padding-top: 80px;

    @media (min-width: $desktop) {
      padding-top: 150px;
    }
  }

  &--technology {
    max-width: 1200px;
    padding: 10em 1.5em 1em;

    > p {
      margin-bottom: 3rem;
    }

    @media (min-width: $desktop) {
      align-items: start;
      max-width: 1340px;

      > p {
        max-width: 600px;
      }
    }
  }

  &--about-us-heading,
  &--about-us {
    max-width: 1080px;
    padding: .5em 1.5em;

    &:first-of-type {
      align-items: start;
      padding-top: 4.5em;

      @media (min-width: $desktop) {
        padding-top: 9em;
      }
    }

    .wrapper__inner {
      padding-left:  0;
      padding-right:  0;
    }

    @media (min-width: $desktop) {
      padding: 3em 1.5em .5em;
    }
  }

  &--about-us--heading {
    max-width: $page-width-block;
  }

  &--careers {
    max-width: $page-width-block;
    padding: .5em 1.5em;

    &:first-of-type {
      align-items: start;
      padding-top: 13em;
    }

    .wrapper__inner {
      padding-left:  0;
      padding-right:  0;
    }

    @media (min-width: $desktop) {
      padding: 3em 1.5em .5em;
    }
  }

  &--developers,
  &--help-center {
    max-width: 1137px;
    margin: auto;

    &:first-of-type {
      padding-top: 16rem;
    }
  }

  &--glossary {
    max-width: $page-width-block;
    padding: 10em 1.5em 1.5em;
  }

  &--glossary-block {
    margin: auto;
    max-width: $page-width-block;
    padding: 4em 1.5em 4rem 3em;

    &:nth-of-type(2n+1) {
      background-color: $grey-4 !important;

      * {
        color: $white;
      }
    }

    h1 {
      position: sticky;
      top: 120px;
    }

    @media (min-width: $desktop) {
      max-width: 100%;

      h1 {
        text-align: center;
        top: 200px;
      }
    }

    .wrapper__inner {
      align-items: baseline;
      display: flex;
      flex-flow: initial;
      gap: 4rem;

      @media (min-width: $desktop) {
        gap: 10%;
      }
    }
  }

  &--our-story {
    > .heading ~ h4 {
      @media (min-width: $desktop) {
        max-width: 810px;
      }
    }

    > .heading ~ p {
      @media (min-width: $desktop) {
        max-width: 830px;
        margin-left: 4rem;
      }
    }
  }

 &--careers-subscribe {
   padding: 2.5em .5em;
   text-align: center;

   .wrapper__inner {
     max-width: 600px;
   }

  @media (min-width: $desktop) {
    background-color: #1F1E1C;
    margin-top: 6rem;
  }
 }

  &--careers-features {
    padding-bottom: 6rem;

    @media (min-width: $desktop) {
      max-width: 800px;
    }

    h2 {
      @media (min-width: $desktop) {
        margin-left: -21rem;
        max-width: 350px;
      }
    }
  }

  &--careers-slides-intro {
    padding-bottom: 6rem;

    h2 {
      @media (min-width: $desktop) {
        max-width: 350px;
      }
    }

    p {
      @media (min-width: $desktop) {
        max-width: 550px;
      }
    }
  }

  &--careers-slides {
    padding: 0;
  }

  &--landing {
    padding: 8em 1.5em 2em;

    .heading {
      max-width: 320px;

      @media (min-width: $desktop) {
        max-width: 100%;
      }
    }

    @media (min-width: $desktop) {
      max-width: 800px;
    }
  }

  &--intro-modules {
    margin-bottom: 4rem;

    @media (min-width: $desktop) {
      max-width: 810px;
    }
  }

  &--404 {
    max-width: $page-width;
    padding: 8em 1.5em;

    &:before {
      content: '';
      @include bg-svg-image('404-hero', 24rem, 30rem, $background-size: true);
      background-position: center;

      @media (min-width: $desktop) {
        @include bg-svg-image('404-hero', 34rem, 35rem, $background-size: true);
        background-position: center;
      }
    }
  }

  // TODO: Replace it to with a more generic
  &--module {
    max-width: $page-width;

    &:before {
      right: -10vw;
      top: 0;
      transform: rotate(-30deg);
    }

    @media (min-width: $desktop) {

      &:before {
        right: -10rem;
        transform: rotate(10deg);
      }
    }

    @media (min-width: $desktop) {
      flex-flow: row;
      gap: 3rem;
    }

    &-catalog {
      @include hero-image('catalog', 30deg);
    }

    &-reporter {
      @include hero-image('reporter', 30deg);
    }

    &-linear {
      @include hero-image('linear', 30deg);
    }

    &-sequence {
      @include hero-image('sequence', 130deg);
    }

    &-catchup {
      @include hero-image('catch-up', 20deg);
    }

    &-rights {
      @include hero-image('rights', 20deg);
    }

    &-publisher {
      @include hero-image('publisher', 20deg);
      top: 2rem;
    }

    &-schedule {
      @include hero-image('schedule', 20deg);
    }
  }

  &--module-features {
    max-width: 1180px;
    padding: 1.8em 1.5em;
  }
}
