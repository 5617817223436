.help-center,
.developers {
  padding: 0 1rem;

  &__heading {
    @extend .heading--xx-large;
    margin-bottom: .5rem;
    text-align: center;
  }

  &__subheading {
    @extend .heading--medium;
    font-family: $base-font;
    font-weight: normal;
  }

  &__presection {
    margin-top: 3rem;
    width: 100%;

    > .heading {
      font-size: 3.6rem;
      line-height: 4.6rem;
    }

    > p {
      width: 100%;

      @media (min-width: $desktop) {
        width: 525px;
      }
    }
  }

  &__section {
    margin-top: 3rem;
    width: 100%;

    > h3 {
      margin-bottom: 1.5rem;
      width: 300px;
    }

    @media (min-width: $desktop) {
      display: flex;
      margin-left: 10rem;
      margin-top: 5rem;
      padding-right: 12rem;
    }

    &-column {
      display: flex;
      flex-flow: column;
      width: 100%;
    }

    &:last-of-type {
      margin-bottom: 4rem;
    }
  }

  &__form {
    display: flex;
    flex-flow: column;
    gap: 2rem;
    margin: 3rem auto;
    width: 100%;

    &-field {
      background-color: white;
      border-radius: 33px;
      border: 1px solid $grey-3;
      padding: 1.15rem 2rem;
      width: 100%;
    }

    button {
      cursor: pointer;
      flex-basis: auto;
      margin: auto;

      @media (min-width: $desktop) {
        flex-basis: 94px;
      }
    }

    @media (min-width: $desktop) {
      flex-flow: row;
      width: 640px;
    }
  }

  &__main {
    margin-bottom: 6rem;
    width: 100%;

    .heading-title {
      @media (min-width: $desktop) {
        margin-bottom: 3rem;
      }

      ~ * {
        @media (min-width: $desktop) {
          margin-left: 15rem;
        }
      }
    }
  }

  &__results {

    &-item {

      > a {
        display: inline-block;
        margin-bottom: 1.5rem;
      }

      > p {
        margin-bottom: 3rem;
      }
    }
  }

  @import "developers/developers.post";
}
