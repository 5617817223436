.tabs {
  input[type="radio"] {
    left: -9999999px;
    position: absolute;
    top: 0;
  }

  &__tab {
    @extend .button;
    @extend .button--grey-1;
    border-radius: 8px;
    cursor: pointer;
    left: $base-unit;
    position: absolute;
    top: 0;
    width: calc(50% - #{$base-unit});

    &--last {
      left: 50%;

      @media (min-width: $desktop) {
        left: 17rem;
      }
    }

    &:hover {
      background-color: $bebanjo-bright;
      color: $white;
    }

    @media (min-width: $desktop) {
      width: 15rem;
    }
  }

  &__content {
    display: none;
    margin-top: $base-unit * 4;
    max-width: $page-width-block;
    overflow: hidden;
    position: relative;
    width: calc(100vw - 10vw);

    @media (min-width: $desktop) {
      width: calc(100vw - 20.4vw);
    }
  }

  [id^="tab"]:checked ~ [id^="tab-content"] {
    display: block;
  }

   [id^="tab"]:checked + label {
    background: $bebanjo;
    color: $white;
  }
}

