
.carousel--clients,
.carousel--careers {
  counter-reset: currentStep 0 remainder 0 totalStep 0;
  overflow-x: clip;

  .carousel-cell {
    counter-increment: totalStep;
    margin-right: 10px;
    padding: 2rem;
    width: 100%;

    @media (min-width: $desktop) {
      margin-left: 20%;
      width: 60%;
    }

    &__text {
      font-family: $base-font;
      font-weight: 600;
      font-size: 2.2rem;
      line-height: 2.6rem;
      margin-bottom: 1.2em;
      margin-top: 1.2em;

      @media (min-width: $desktop) {
        font-size: 2.8rem;
        line-height: 3.5rem;
      }
    }

    &__foot {
      font-size: 1.4rem;

      @media (min-width: $desktop) {
        font-size: 1.8rem;
      }

      &-from {
        font-weight: 600;
        margin-bottom: 0;
      }

      &-link {
        color: $bebanjo;
        font-weight: 600;
      }
    }
  }

  .flickity-page-dots {
    align-items: center;
    display: flex;
    height: 35px;
    padding-left: 2rem;
    text-align: left;
    top: -3rem;

    @media (min-width: $desktop) {
      display: flex;
      flex-flow: column;
      justify-content: center;
      height: auto;
      width: 20%;
    }

    .dot {
      align-items: center;
      counter-increment: currentStep;
      display: flex;
      height: 8px;
      justify-content: center;
      opacity: 1;
      width: 8px;

      @media (min-width: $desktop) {
        margin-bottom: 3rem;
      }

      &.is-selected {
        background-color: transparent;
        border: 2px solid $grey-4;
        font-size: 1.1rem;
        height: 35px;
        width: 35px;

        &:before {
          content: counter(currentStep) "/" counter(totalStep);
        }
      }
    }
  }
}

.carousel--careers {
  .carousel-cell.is-selected {

    .carousel-cell__text {
      @media (min-width: $desktop) {
        margin-left: -15rem;

        ~ p {
          margin-left: -15rem;
        }
      }
    }
  }

  .dot {
    background: $white !important;

    &.is-selected {
      background-color: transparent !important;
      border-color: $white !important;
    }
  }
}

