@keyframes slideDown {
  0% {
    transform: translateY(-10px) rotate(45deg);
  }

  99% {
    transform: translateY(10px) rotate(45deg);
  }
}

@keyframes slideDown2 {
  0% {
    transform: translateY(-5px);
  }

  99% {
    transform: translateY(5px);
  }
}

@keyframes rotate {
  0% {
    transform: rotate(85deg);
  }

  99% {
    transform: rotate(95deg);
  }
}

@keyframes rotate2 {
  0% {
    transform: rotate(85deg);
  }

  99% {
    transform: rotate(91deg);
  }
}

.landing {
  overflow: visible;

  main {
    @media (max-width: $desktop) {
      overflow: hidden;
    }
  }
}

.logo-landing {
  position: absolute;
  width: clamp(5rem, calc(5rem + 7vw), 30rem);

  > svg {
    height: 100%;
    width: 100%;
  }

  &--catchup {
    animation: 3s infinite alternate rotate;
    left: 0;
    top: 50px;
    transform: rotate(95deg);
  }

  &--publisher {
    animation: 3s infinite alternate slideDown2;
    top: 80px;
    right: -40px;
    transform: translateY(5px);
  }

  &--rights {
    animation: 3s infinite alternate slideDown;
    left: 0;
    top: clamp(30rem, calc(30rem + 14vw), 80rem);
    transform: translateY(10px) rotate(45deg);
  }

  &--reporter {
  	animation: 2s infinite alternate rotate2;
    bottom: 300px;
    right: 0;
    transform: rotate(91deg);
  }
}
